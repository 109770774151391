import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import talks from '../resources/talks'
import './talks.scss'

import talks1 from '../images/talks1.jpg'
import talks2 from '../images/talks2.jpg'

const sortableDate = str =>
  str
    .split('-')
    .reverse()
    .join('-')

talks
  .sort((a, b) => sortableDate(a.date).localeCompare(sortableDate(b.date)))
  .reverse()

const TalksPage = () => (
  <Layout>
    <SEO title="Conference talks" />
    <center>
      <h1>Conference talks</h1>
      <img alt="Michel @ reactive-conf 2018, Prague" src={talks1} />
    </center>
    {talks.map(talk => (
      <TalkTile talk={talk} key={talk.date} />
    ))}
    <img alt="Michel @ frontend-love conf 2018, Amsterdam" src={talks2} />
  </Layout>
)

const TalkTile = ({ talk }) => (
  <div class="talk">
    <h2>
      <a href={talk.youtube || '#'} target="_blank" rel="noopener noreferrer">
        {talk.star ? '⭐ ' : ''}
        {talk.title}
        {talk.star ? ' ⭐' : ''}
      </a>
    </h2>
    <hr />
    {talk.conference} &mdash; {talk.date} &mdash;{' '}
    <a href={talk.youtube || '#'} target="_blank" rel="noopener noreferrer">
      Video
    </a>{' '}
    {talk.slides && '| '}
    {talk.slides && (
      <a href={talk.slides} target="_blank" rel="noopener noreferrer">
        Slides
      </a>
    )}
  </div>
)

export default TalksPage
