export default [
  {
    youtube:
      'https://www.youtube.com/watch?v=-gJbS7YjcSo&index=30&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://immer.surge.sh/',
    conference: 'React Finland (2018)',
    title: 'Immer: Immutability made easy',
    date: '04-04-2018',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=N2mSG28jADQ&index=28&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides:
      'https://docs.google.com/presentation/d/1qLc4eQrfI1w-3f1HG_STHYFvwd8vqKp99yylbGA3suk/edit?usp=sharing',
    conference: 'ReactiveConf (2018)',
    title: 'State management beyond the libraries',
    date: '29-10-2018',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=clTP9hJWsrE&index=27&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://thinkinginreact.surge.sh/',
    conference: 'Frontend Developer Love (2018)',
    title:
      "React: It's not a framework for the UI, but a framework for the mind",
    date: '23-02-2018',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=FEwLwiizlk0&index=26&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides:
      'https://docs.google.com/presentation/d/16hE-cxJ8C5XQVjql17krNAeYNF_9I3n3j13ho-KLWYU/edit?usp=sharing',
    conference: 'ReactiveConf (2015)',
    title:
      'React, transparent reactive programming and mutable data structures',
    date: '04-11-2015',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=rwqwwn_46kA&index=12&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://tree.surge.sh',
    conference: 'ReactEurope (2017)',
    title: 'Next generation state management',
    date: '18-05-2017',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=ApmSsu3qnf0&index=10&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides:
      'https://docs.google.com/presentation/d/1tP0VWjproXsZex3R8zWCA7Bra7RtMcu4daJXCUbs8pc/edit?usp=sharing',
    conference: 'React Amsterdam (2016)',
    title: 'State Management Is Easy',
    date: '16-04-2016',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=Ql8KUUUOHNc&index=9&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://immer-mutable-state.surge.sh/#1',
    conference: 'ReactiveConf (2016)',
    title: 'Immer-mutable state management',
    date: '28-10-2016',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=3J9EJrvqOiM&index=7&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://divideandconquer.surge.sh',
    conference: 'React Amsterdam (2017)',
    title: 'Complexity: Divide and Conquer!',
    date: '21-04-2017',
    star: true,
  },

  {
    youtube:
      'https://www.youtube.com/watch?v=Aws40KOx90U&index=6&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides:
      'https://docs.google.com/presentation/d/1DrI6Hc2xIPTLBkfNH8YczOcPXQTOaCIcDESdyVfG_bE/edit?usp=sharing',
    conference: 'React Next (2016)',
    title: 'Becoming a ruler of state',
    date: '15-09-2016',
    star: true,
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=TfxfRkNCnmk&index=4&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://magixmobx.surge.sh ',
    conference: 'RuhrJS 2016',
    title: 'Magic MobX',
    date: '03-07-2016',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=xfC_xEA8Z1M&index=3&t=0s&list=PLW0vzLDjfaNSFs7OBLK6anfQiE5FJzAPD',
    slides: 'http://react-next-2017-slides.surge.sh/',
    conference: 'React Next (2017)',
    title: 'React, but for data',
    date: '10-09-2017',
  },
  {
    youtube: 'https://www.youtube.com/watch?v=9Lfs0GwWw1g',
    slides:
      'https://docs.google.com/presentation/d/1bf_U4vUTTLDYDPV-JTZsEBsYLqPzJj9HzNVRHT9eLlQ/edit?usp=sharing',
    conference: 'Agent.sh (2018)',
    title: 'Go home (or skiing) early. The joy of transparent reactivity.',
    date: '25-01-2018',
  },
  {
    youtube: 'https://www.youtube.com/watch?v=Gyp2QDr7YkU',
    slides:
      'https://docs.google.com/presentation/d/1s5x3tgnhV5ZlXBBbKJXApWAmzMc_jWkkwtrZ1S8rUFY/edit?usp=sharing',
    conference: 'React Amsterdam (2018)',
    title: 'There and back again: grokking state and data',
    date: '13-04-2018',
  },
  {
    youtube: 'https://www.youtube.com/watch?v=010daBQPFmw&t=0s',
    slides: 'http://funtypes.surge.sh ',
    conference: 'ReactEurope (2018)',
    title: 'The fun of runtime type systems',
    date: '18-05-2018',
    star: true,
  },
  {
    youtube: 'https://www.youtube.com/watch?v=FKRVDfpQ9cw&t=0s',
    slides: 'http://immer-react-next-2018.surge.sh',
    conference: 'ReactNext (2018)',
    title: 'Immer, Immutability & the Wonderful World of Proxies',
    date: '04-11-2018',
    star: true,
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=Kyck5UJFVx8&t=4377s&list=PL28vf039uxK8lY-cdegZtix3wYbG7TIx7',
    slides:
      'https://assets.ctfassets.net/nn534z2fqr9f/Ezk73ofhW8sCC8wuIA4iE/f6c12f39312901e55889a5fda0d410e9/Michel_Weststrate_State_management_beyond_libraries.pdf',
    conference: 'HolyJS (2018)',
    title: 'State management beyond the libraries',
    date: '24-11-2018',
  },
  {
    youtube: 'https://www.youtube.com/watch?v=hHF33WGqQ5U',
    slides: 'http://yglfmobx.surge.sh',
    conference: 'You Gotta Love Frontend CodeCamp Tel Aviv (2019)',
    date: '03-04-2019',
    title: 'Modern React and the case for Reactive State Management',
  },
  {
    youtube: 'https://www.youtube.com/watch?v=MIh1qSHi_Fc&feature=youtu.be',
    slides:
      'https://docs.google.com/presentation/d/12Dd8iYxcA8Wl2yaOE_FNb-MaB7cktUE82JFXVlrO-6U/edit?usp=sharing',
    conference: 'React Finland (2019)',
    date: '25-04-2019',
    title: 'MobX - the journey',
    star: true,
  },
  {
    youtube: 'https://vimeo.com/242889740',
    slides:
      'https://mweststrate.github.io/reactjsday2017-presentation/index.html#1',
    conference: 'ReactJS Day (2017)',
    date: '06-10-2017',
    title: 'Mutable or Immutable? Why not both! (With Mattia Manzati)',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=Sq2M00vghqY&list=PLCC436JpVnK3kcTnPyhcs7QnHK2PKl33D&index=12',
    slides:
      'https://docs.google.com/presentation/d/1KlG-xAOiFJAu-4NYBQJhFea5homFu9K2BE5gtvJ7dx0/edit?usp=sharing',
    conference: 'React Europe (2019)',
    date: '23-05-2019',
    title: 'Data models all the way: Combining GraphQL + mobx-state-tree',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=NBYbBbjZeX4&list=PL8sJahqnzh8JJD7xahG5zXkjfM5GOgcPA&index=21&t=0s',
    conference: 'HolyJS Moscow (2019)',
    date: '25-05-2019',
    title: 'MobX and the unique symbiosis of predictability and speed',
    slides: 'https://holyjs2019slides.surge.sh/#1',
  },
  {
    youtube:
      'https://www.youtube.com/watch?v=4Nb9Gwp2L24&list=PLfIM4SvaiIyygQEe2WPpENwxIf-0agBr9&index=13&t=0s',
    slides: 'https://jsnation-proxies.surge.sh',
    conference: 'JSNation (2019)',
    date: '06-08-2019',
    title: 'Immer, Immutability and the Wonderful World of Proxies',
  },
  {
    title:
      '📻 State Management with Taras Mankovski, Tim Dorr, Michel Weststrate, and Charles Lowell',
    youtube:
      'https://dev.to/modernweb/s05e01-state-management-with-taras-mankovski-tim-dorr-michel-weststrate-and-charles-lowell',
    date: '02-08-2018',
    conference:
      'Modern Web Podcast S05E01, with Taras Mankovski, Tim Dorr, Charles Lowell',
  },
  {
    title: '📻 Reactive Programming with MobX',
    youtube:
      'https://www.mixcloud.com/meteorclubpodcast/reactive-programming-with-mobx/',
    conference: 'SpaceDojo with Josh Owens',
    date: '07-01-2017', // 2 years ago
  },
  {
    title: '📻 Immer & MobX State Tree feat. Michel Weststrate',
    youtube:
      'https://devchat.tv/react-native-radio/immer-mobx-state-tree-feat-michel-weststrate/',
    conference:
      'React Native Radio, Episode 92, with Nader Dabit and Spencer Carli',
    date: '29-03-2018',
  },
  {
    title: '📻 Live panel React Amsterdam 2019',
    youtube:
      'https://email.changelog.com/t/n/t-l-c01d95c562e011e9aa0e6f755b884484-l-r-r-l/',
    conference:
      'JS Party, Episode 72, with Kevin Ball, Kitze, Mike Grabowski, Vladimir Novick, Andrey Okonetchnikov',
    date: '19-04-2019',
  },
  {
    title: '📻 Interviews related to State Management',
    youtube: 'https://webbidevaus.fi/43',
    conference:
      'React Finland 2019 interviews, with David Khoursid, Luca Matteis, Farzad Yz, Jakson Valinnat',
    date: '19-05-2019',
  },
  {
    title: '📻 Michel Westrate Wants You To Stop Writing State Reducers',
    youtube: 'https://reactpodcast.simplecast.fm/51',
    conference: 'React Podcast, Episode 51 with Micheal Chan',
    date: '20-06-2019',
  },
  {
    title:
      'Beyond virtual lists: Introducing FSRW transformations to render large, frequently updating datasets responsively',
    youtube: 'https://www.youtube.com/watch?v=WCs09c1B1Lk',
    conference: "JavaScript fwdays'21",
    date: '06-05-2021',
  },
  {
    title:
      'MobX, or: Going from Mutable to Immutable to Reactive State Management',
    youtube: 'https://www.youtube.com/watch?v=ZHxFrbK3VB0',
    conference: 'CityJS 2020',
    date: '07-10-2020',
  },
  {
    title: 'Flipper: The Extensible DevTool Platform for React Native',
    youtube: 'https://www.youtube.com/watch?v=bI1VEXVzNXs',
    conference: 'React Summit 2020',
    date: '14-12-2020',
  },
  {
    title: 'Flipper: The Extensible DevTool Platform for React Native',
    conference: 'React Europe 2020',
    youtube: 'https://www.youtube.com/watch?v=WltZTn3ODW4',
    date: '17-05-2020',
  },
  {
    title: 'Beyond Virtual Lists: How to Render 100K Items with 100s of Updates/sec in React',
    conference: 'React Advanced London 2021',
    date: '22-10-2021',
    youtube: 'https://portal.gitnation.org/contents/beyond-virtual-lists-how-to-render-100k-items-with-100s-of-updatessec-in-react',
  },
  {
    title: 'A Career as Software Engineer',
    youtube: 'https://www.youtube.com/watch?v=SwRSmdIcT1M',
    conference: 'React Advanced London 2022',
    date: '21-10-2022',
  },
  {
    title: 'Reactivity: There and Back Again',
    conference: 'React Summit 2023',
    youtube: 'https://portal.gitnation.org/contents/reactivity-there-and-back-again',
    date: '02-06-2023',
  }
]
